//
// Copyright 2023 DXOS.org
//

export const translationKey = 'menus';

export default [
  {
    'en-US': {
      [translationKey]: {},
    },
  },
] as const;
